import React, { Component } from "react";
import hedge from '../../assets/images/icons/Hedge Logo White BG.png'
import hedgeDark from '../../assets/images/icons/In app Logo.png'
import {connect} from "react-redux";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import {FB_KEY, GOOGLE_KEY} from "../../constants";

class SignIn extends Component {
    constructor(props) {
        super();
        this.state = {
            username: '',
            password: '',
            error: {
                username: '',
                password: ''
            },
            version: '7.0',
            showVersion: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.toggleVersion = this.toggleVersion.bind(this);
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    toggleVersion(e) {
        this.setState({showVersion: !this.state.showVersion});
    }
    componentDidMount() {

    }
    onLogin() {
        let errors = 0;
        let error = {
            username: "",
            password: ""
        };

        if (this.state.password === "") {
            error["password"] = "Enter password";
            errors++;
        }
        if (this.state.username === "") {
            error["username"] = "Enter username";
            errors++;
        } else if (
            !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.username)
        ) {
            error["username"] = "Enter a valid email";
            errors++;
        }

        if (errors === 0) {
            this.props.loginEmail(this.state);
        }



        this.setState({ error: error });
    }
    render() {


        return (
            <React.Fragment>

                <div className="login-wrapper">

                    <div className="login-panel">

                        <div className="login-header">
                            <a href="/">
                            <img src={hedge} className="light" alt="logo"/>
                            <img src={hedgeDark} className="dark" alt="logo"/>
                            </a>

                            <a href="/" className="close-panel"> </a>
                        </div>

                        <div className="login-content">
                            <div className="input-field">
                                <label>Username</label>
                                <input type="text" placeholder="Username" name="username" value={this.state.username} onChange={this.handleChange} />
                                <div className={`error-field${this.state.error.username === '' ? ' hide' : ''}`}>{this.state.error.username}</div>
                            </div>
                            <div className="input-field">
                                <label>Password</label>
                                <input type="password" placeholder="Password"  name="password" value={this.state.password} onChange={this.handleChange} />
                                <div className={`error-field${this.state.error.password === '' ? ' hide' : ''}`}>{this.state.error.password}</div>
                                {this.state.error.password === '' &&
                                this.props.errorMessage.status === true &&
                                this.props.errorMessage.element === "LOGIN" && (
                                    <div className={`error-field`}>
                                      {this.props.errorMessage.message}
                                    </div>
                                )}
                            </div>

                            <div className="forgot">
                                <a href="/forgot-password">Forgot Password?</a>
                            </div>

                            <div className="submit-block">

                                <div className="direct-login-box">
                                    <button className="general-btn" onClick={this.onLogin}>Login</button>

                                </div>

                                {/*<div className="divider-text"><span>Or</span></div>

                                <div className="alt-login-box">
                                    <GoogleLogin
                                        clientId={GOOGLE_KEY}
                                        buttonText="Login with Google"
                                        className="gl long icon-btn btn"
                                        onSuccess={this.props.loginGoogle}
                                        onFailure={this.props.loginGoogle}
                                        icon={false}
                                        cookiePolicy={"single_host_origin"}
                                    />
                                    <FacebookLogin
                                        appId={FB_KEY}
                                        fields="first_name,last_name,email,picture"
                                        callback={this.props.loginFb}
                                        textButton="Login with Facebook"
                                        cssClass="fb long icon-btn btn"
                                        isMobile={false}
                                        responseType="codesignup"
                                    />
                                </div>*/}

                            </div>

                        </div>
                        <div style={{position: "absolute", bottom: "0px", right: "0px", width: "20px", height: "20px"}} onClick={this.toggleVersion}>
                            {this.state.showVersion &&
                            <p style={{
                                color: '#903e97',
                                fontSize: "10px"
                            }}>{this.state.version}</p>
                            }
                        </div>
                    </div>

                </div>

                {/* <div className="anchor-links">
                    <a>Home</a>  /  <a>Sign In</a>  /  <a className="current-page">Tata Motors’ bet on new models...</a>
                </div>

                SignIn
                <div className="alt-login-box">
                    <GoogleLogin
                        clientId={GOOGLE_KEY}
                        buttonText="Signup with Google"
                        className="gl long icon-btn btn"
                        onSuccess={this.props.signupGoogle}
                        onFailure={this.props.signupGoogle}
                        icon={false}
                        cookiePolicy={"single_host_origin"}
                    />
                    <FacebookLogin
                        appId={FB_KEY}
                        fields="first_name,last_name,email,picture"
                        callback={this.props.signupFb}
                        textButton="Signup with Facebook"
                        cssClass="fb long icon-btn btn"
                        isMobile={false}
                        responseType="codesignup"
                    />
                </div> */}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        //loader: actionReducers.getLoader(state),
        //storyDetails: actionReducers.getStoryDetails(state),
    }
}
export default connect(mapStateToProps, {
    //findStoryDetails: ActionCreators.findStoryDetails
})(SignIn);
