import React, { Component } from "react";

import hedge from '../../assets/images/icons/Hedge Logo White BG.png'
import hedgeDark from '../../assets/images/icons/In app Logo.png'
import * as actionReducers from "../../reducers";
import {connect} from "react-redux";
import * as ActionCreators from "../../actions";

class ForgotPassword extends Component {
    constructor(props) {
        super();
        this.state = {
            email: '',
            error: {
                email: ''
            },
            message: ''

        }
        this.forgotPassword = this.forgotPassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.forgotPasswordMessage.message !== prevProps.forgotPasswordMessage.message) {
            this.setState({message: this.props.forgotPasswordMessage.message});
        }
    }


    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    forgotPassword() {
        let errors = 0;
        let error = {
            email: ""
        };
        if (this.state.email === "") {
            error["email"] = "Enter email";
            errors++;
        } else if (
            !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)
        ) {
            error["email"] = "Enter a valid email";
            errors++;
        }
        if(errors === 0) {
            this.props.forgotPassword({email: this.state.email});
        }
        this.setState({error: error});
    }
    render() {


        return (
            <React.Fragment>

                <div className="login-wrapper">

                    <div className="login-panel">

                        <div className="login-header">
                            <a href="/">
                                <img src={hedge} className="light"  alt="Logo"/>
                                <img src={hedgeDark} className="dark" alt="logo"/>
                            </a>
                        </div>

                        <div className="login-content">
                           <div class="each-input-field">
                            <label>Email</label>
                            <input type="text" name="email" value={this.state.email}
                                   onChange={this.handleChange}/>
                            <div
                                className={`error-field${this.state.error.email === '' ? ' hide' : ''}`}>{this.state.error.email}</div>
                               {(this.state.error.email === '' && this.state.message !== '') &&
                               <div
                                   className={`error-field`}>{this.state.message}</div>
                               }
                           </div>


                            <div className="forgot">
                                <a href="sign-in">Back to Login?</a>
                            </div>

                            <div className="submit-block">

                                <div className="direct-login-box">
                                    <button className="general-btn" onClick={this.forgotPassword}>Submit</button>
                                </div>





                            </div>
                        </div>

                    </div>

                </div>


            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: actionReducers.getLoader(state),
        forgotPasswordMessage: actionReducers.getForgotPassword(state),
    }
}
export default connect(mapStateToProps, {
    forgotPassword: ActionCreators.forgotPassword
})(ForgotPassword);
