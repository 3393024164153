import React, {Component} from 'react'
import Logo from '../../assets/images/icons/In app Logo.png'
import hedge from '../../assets/images/icons/Hedge Logo White BG.png'
import hedgeDark from '../../assets/images/icons/In app Logo.png'
import {Scrollbars} from "react-custom-scrollbars";


class Navbar extends Component {
    constructor (props) {
        super(props);
        this.state = {
            sidebar: false,
            showSearch: false,
            search: ''
        }
        this.searchToggle = this.searchToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.filterToggler = this.filterToggler.bind(this);
        this.userLogout = this.userLogout.bind(this);
        this.logout = this.logout.bind(this);
    }

    asideToggler = () => {
        this.setState({ sidebar: !this.state.sidebar});
        document.body.classList.toggle('overlay');
    }
    searchToggle() {
        this.setState({showSearch: !this.state.showSearch});
    }
    changeSection(section) {
        this.props.changeSection(section);
        this.asideToggler();
    }
    handleChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    handleKeyPress(e) {
        var keycode = (e.keyCode ? e.keyCode : e.which);
        if (keycode === '13') {
            this.onSearch();
        }
    }
    onSearch() {

        this.props.applySearch(this.state.search);
        this.setState({search: ''});
        this.searchToggle();

    }
    filterToggler() {
        this.asideToggler();
        this.props.filterToggler(true);
    }
    logout() {
        this.props.userLogout();
        this.props.toggleModal();
    }
    userLogout() {
        this.props.toggleModal(
            true,
            'Sign Off',
            'Are you sure you want to sign off?',
            this.logout,
            true,
            this.props.toggleModal,
            'md'
        );
    }

    render() {
        //const { logged } = this.props;
        let ud = localStorage.getItem('ud') ? JSON.parse(atob(localStorage.getItem('ud'))) : {};

        let loggedIn = ud.email && ud.email !== '' ? true : false;
        //console.log(ud)
        return (
            <>
                <aside className={this.state.sidebar ? "aside-menu fly-in" : "aside-menu"}>
                    <button className="aside-close" onClick={() => this.asideToggler()}/>
                    <div className="aside-menu-container">
                        <figure className="sidebar-logo" onClick={(e) => this.changeSection('')}>
                            <img src={Logo} alt="logo"/>
                        </figure>
                        <div className="aside-menu-top">
                            <Scrollbars autoHide autoHideTimeout={1000}>
                                <div className="aside-menu-top-inner">
                                    <ul className="menu-listing">
                                        <li className={`${document.location.pathname === '/' && localStorage.getItem('section') === '' ? 'active' : ''} hide-lg`}>
                                            <a onClick={(e) => this.changeSection('')}>HOME</a>
                                        </li>
                                        {this.props.sections.map((section, sectionIndx) =>
                                            <li key={`menu-section-${sectionIndx}`} className={`${(section.text_content_sections[0] === localStorage.getItem('section') && document.location.pathname !== "/bookmarks") ? 'active' : ''} hide-lg`}>
                                                <a onClick={(e) => this.changeSection(section.text_content_sections[0])}>{section.text_content_sections[0].toUpperCase()}</a>
                                            </li>
                                        )}

                                        <li className={`hide-lg ${(document.location.pathname === "/bookmarks") ? 'active' : ''}`}>
                                            <a href="/bookmarks">BOOKMARKS</a>
                                        </li>
                                        <li className="hide-lg">
                                            <a onClick={this.filterToggler}>FIND STORIES</a>
                                        </li>
                                        <li className="hide-lg">
                                            <a className="non-link">VISIT HEDGE AT</a>
                                            <ul className="social-media-block">
                                                <li>
                                                    <a className="web-ico" href={this.props.socialLinks['Website']} target="_blank" rel="noreferrer">hedgeequities.com</a>
                                                </li>
                                                <li>
                                                    <a className="yt-ico" href={this.props.socialLinks['YouTube Channel']} target="_blank" rel="noreferrer">Youtube</a>
                                                </li>
                                                <li>
                                                    <a className="fb-ico" href={this.props.socialLinks['Facebook']} target="_blank" rel="noreferrer">Facebook</a>
                                                </li>
                                                <li>
                                                    <a className="tw-ico" href={this.props.socialLinks['Twitter']} target="_blank" rel="noreferrer">Twitter</a>
                                                </li>
                                                <li>
                                                    <a className="ig-ico" href={this.props.socialLinks['Instagram']} target="_blank" rel="noreferrer">Instagram</a>
                                                </li>
                                                <li>
                                                    <a className="ln-ico" href={this.props.socialLinks['LinkedIn']} target="_blank" rel="noreferrer">LinkedIn</a>
                                                </li>
                                            </ul>
                                        </li>
                                        {loggedIn === false ?
                                            <li>
                                                <a href="/subscribe">SUBSCRIBE NOW</a>
                                            </li>
                                            :
                                            <li>
                                                <a href="/my-account">MY ACCOUNT</a>
                                            </li>
                                        }
                                        <li>
                                            <a href="/about-us">ABOUT US</a>
                                        </li>
                                        <li>
                                            <a href="/terms-of-use">TERMS OF USE</a>
                                        </li>
                                        <li>
                                            <a href="/privacy-policy">PRIVACY POLICY</a>
                                        </li>
                                        <li>
                                            <a href="/contact-us">CONTACT US</a>
                                        </li>
                                        {/*<li>
                                            <a href="http://magazine.hedgeohari.com/" target="_blank">MAGAZINE</a>
                                        </li>*/}
                                    </ul>
                                </div>
                            </Scrollbars>
                        </div>

                        <div className="aside-footer hide">
                            <div className="redirect">
                                <a href="http://hedgeequities.com/">VISIT HEDGEEQUITIES.COM</a>
                            </div>
                            <div className="other-info">
                                <p>Get up to date information about business & investing</p>
                                <div className="other-info-button-block">
                                    <a href="/subscribe" className="border-btn">SUBSCRIBE NOW</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
                <nav className={loggedIn ? 'Navbar userIn' : 'Navbar userOut'}>
                    <div className="container">
                        <div className="navbar-container">

                            <div className="navbar-menu">
                                <button className="menu" onClick={() => this.asideToggler()}></button>
                            </div>
                            <figure className="brand-logo" onClick={(e) => this.props.changeSection('')}>
                                <img src={hedge} className="light" alt="logo"/>
                                <img src={hedgeDark} className="dark" alt="logo"/>

                            </figure>
                            <div className="navbar-right">
                                {localStorage.getItem("platform") !== 'ios' &&
                                    <a className="general-btn subscribe" href="/subscribe">SUBSCRIBE</a>
                                }

                                <a className="bookmark" href="/bookmarks">{this.props.bookmarks.length}</a>

                                <div className="search-block">
                                    <button className="search-btn" onClick={this.searchToggle}></button>
                                </div>
                                {loggedIn ?
                                    <a className="signin active" onClick={this.userLogout}>SIGN OFF</a>
                                    :
                                    <a className="signin" href="/sign-in">SIGN IN</a>
                                }
                            </div>

                        </div>



                    </div>

                </nav>
                <div className={`search-wrapper${this.state.showSearch === true ? ' fly-in' : ''}`}>
                    <div className="container">
                        <div className="search-input">
                            <input type="text" placeholder="Search Here"
                                   name="search"
                                   onChange={this.handleChange}
                                   onKeyPress={this.handleKeyPress}
                                   value={this.state.search}/>
                            <button className="general-btn"onClick={this.onSearch}>Search</button>
                        </div>
                        <button className="close-panel" onClick={this.searchToggle}></button>
                    </div>
                </div>
            </>
        );
    }
}

export default Navbar;
